import Link from 'next/link'
import styles from '@/styles/ReviewButton.module.css'
import { useTranslation } from 'next-i18next'
const ReviewButton = () => {
  const reviewLink = 'https://g.page/r/CXwfBZPsByx3EBM/review' // Replace with your actual link
  const { t } = useTranslation()
  return (
    <Link
      href={reviewLink}
      title='Review Link'
      target='_blank'
      rel='noopener noreferrer'
    >
      <button className={styles.btn}>
        <span aria-label={t('Your opinion is important')}>
          {t('Give a review')}
        </span>
      </button>
    </Link>
  )
}

export default ReviewButton
