// export default ShareableLink
// import React, { useTransition } from 'react'
import Image from 'next/image'
import styles from '@/styles/SharableLink.module.css'
import Facebook from '@/public/icons/facebook.svg'
import TwitterX from '@/public/icons/twitterx.svg'
import Linkedin from '@/public/icons/linkedin.svg'
import Whatsapp from '@/public/icons/whatsapp.svg'
import { useTranslation } from 'next-i18next'
const SharableLink = () => {
  const { t, i18n } = useTranslation()
  const handleShareWhatsApp = () => {
    const whatsAppShareURL = `https://wa.me/?text=${encodeURIComponent(
      'La Bambi Restaurang'
    )}%20${encodeURIComponent('https://labambi.se/')}`
    window.open(whatsAppShareURL, '_blank')
  }

  const handleShareFacebook = () => {
    const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      'https://labambi.se/'
    )}`
    window.open(facebookShareURL, '_blank')
  }

  const handleShareTwitter = () => {
    const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      'https://labambi.se/'
    )}&text=${encodeURIComponent('La Bambi Restaurang')}`
    window.open(twitterShareURL, '_blank')
  }

  const handleShareLinkedIn = () => {
    const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      'https://labambi.se/'
    )}`
    window.open(linkedInShareURL, '_blank')
  }

  return (
    <section className={styles.sharableLinkContainer}>
      <header>
        <h2>{t('Share with Friends')}</h2>
      </header>
      <main>
        <div className={styles.sharableLinkWrapper}>
          <button
            className={styles.sharableBtn}
            onClick={handleShareFacebook}
            onTouchStart={handleShareFacebook}
            aria-label={`Facebook ikon att ${t('Share with Friends')}`}
          >
            <Image src={Facebook} alt='Facebook Icon' />
          </button>
          <button
            className={styles.sharableBtn}
            onClick={handleShareTwitter}
            onTouchStart={handleShareTwitter}
            aria-label={`Twitter ikon att ${t('Share with Friends')}`}
          >
            <Image src={TwitterX} alt='Twitter X Icon ' />
          </button>
          <button
            className={styles.sharableBtn}
            onClick={handleShareLinkedIn}
            onTouchStart={handleShareLinkedIn}
            aria-label={`Linkedin att ${t('Share with Friends')}`}
          >
            <Image src={Linkedin} alt='Linkedin Icon' />
          </button>
          <button
            className={styles.sharableBtn}
            onClick={handleShareWhatsApp}
            onTouchStart={handleShareWhatsApp}
            aria-label={`Whatsapp att ${t('Share with Friends')}`}
          >
            <Image src={Whatsapp} alt='WhatsApp Icon' />
          </button>
        </div>
      </main>
    </section>
  )
}

export default SharableLink
