import styles from '../styles/Header.module.css'
import GoogleReviews from './GoogleReviews'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import Delevery from './Delivery'
const ImageCarousel = dynamic(() => import('@/components/ImageCarousel'))
const SearchBarFilter = dynamic(() => import('@/components/SearchBarFilter'))

const Header = () => {
  const { t, i18n } = useTranslation()
  return (
    <header className={styles.headerWrapper}>
      <section className={styles.headerTop}>
        <ImageCarousel />
        <SearchBarFilter />
        <GoogleReviews />
        <section aria-labelledby='aboutArticle' className={styles.aboutArticle}>
          <h2 id='aboutArticle' className={styles.spanDays}>
            {t('Daily')}
          </h2>
          <span className={styles.lunchHour}>
            Lunch <abbr aria-label='från'>fr</abbr> 120kr
          </span>
          <p>
            {t('Served Weekdays')}
            <time dateTime='10:30'> 10:30 </time>till
            <time dateTime='14:00'> 14:00 </time>
          </p>
          <p>
            <abbr aria-label='Inklusive'>Inkl</abbr>. Sallad & läsk 33cl
          </p>
        </section>
        <div className={styles.gluten}>
          <h2>{t('It works well with cards or Swish')}</h2>
          <h3>
            {t('Gluten-free SEK 30 / Vegan cheese SEK 10 / Mozzarella SEK 10')}
            <div className={styles.price}>Extra</div>
          </h3>
          <p className={styles.glutenPara}>
            {t('All pizzas can be served gluten-free, except baked pizzas')}
          </p>
          <p
            className={styles.allergyWarning}
            aria-label='Allergy Warning: If you have any allergies, please contact the staff for assistance.'
          >
            <strong className={styles.allergyStrong}>
              {t('Allergy warning')}
            </strong>
            {t('IF YOU HAVE ANY ALLERGIES PLEASE CONTACT THE STAFF!!')}
          </p>
        </div>
        <section aria-labelledby='openTime' className={styles.openTime}>
          <h2 id='openTime'>{t('Opening hours')}</h2>
          <span>
            {t('MON-FRI')} <time dateTime='10:30'>10:30</time>
            <span> till </span>
            <time dateTime='22:00'>22:00</time>
          </span>
          <span>
            {t('SAT-SUN')} <time dateTime='11:00'>11:00</time>
            <span> till </span>
            <time dateTime='22:00'>22:00</time>
          </span>
        </section>
        <address className={styles.address}>Sparres väg 1 197 33 Bro</address>
      </section>
      <Delevery />
    </header>
  )
}

export default Header
